import { i18n } from "element-react"
import locale from "element-react/src/locale/lang/ja"
import "element-theme-default"
import * as React from "react"
import Normalize from "../../js/Components/normalizeCss"
import "../sass/app.scss"
import Bugsnag, { Event } from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
i18n.use(locale)

start()

async function start() {
  const [ReactDOM, { Provider }, { default: configureStore }, { default: Routes }, { PersistGate }] = await Promise.all([
    import(/* webpackChunkName: "react-dom" */ "react-dom"),
    import(/* webpackChunkName: "react-redux" */ "react-redux"),
    import(/* webpackChunkName: "store" */ "./Store/configureStore"),
    import(/* webpackChunkName: "routes" */ "./Routes"),
    import(/* webpackChunkName: "redux-persist" */ "redux-persist/integration/react"),
  ])

  const { store, persistor } = configureStore()

  const bugSnagApiKey = process.env.BUGSNAG_KEY
  const appVersion = process.env.RELEASE

  if (bugSnagApiKey) {
    Bugsnag.start({
      apiKey: bugSnagApiKey,
      plugins: [new BugsnagPluginReact()],
      appVersion: appVersion,
      onError: (event: Event) => {
        const statusCode = event.originalError && event.originalError.errors && event.originalError.errors.status
        return statusCode !== 422
      },
    })
  }

  const ErrorBoundary = bugSnagApiKey
    ? Bugsnag.getPlugin("react")?.createErrorBoundary(React)
    : ({ children }: { children: JSX.Element }) => {
        return <>{children}</>
      }
  ErrorBoundary &&
    ReactDOM.render(
      <ErrorBoundary>
        <Provider store={store as any}>
          <Normalize />
          <PersistGate loading={null} persistor={persistor}>
            <Routes />
          </PersistGate>
        </Provider>
      </ErrorBoundary>,
      document.getElementById("root")
    )
}
